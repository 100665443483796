
import { defineStore } from 'pinia'
import { AutoNumberCardItem } from "~/types";

export const useResultsStore = defineStore('resultsStore', {
  state: () => ({
    metricNumbers: [
      {
        id: 1,
        mediaSrc: "mdi-account-outline",
        prefix: "+ ",
        value: 560,
        sufix: " mil",
        startValue: 0,
        text: "clientes atendidos"
      },
      {
        id: 2,
        mediaSrc: "mdi-message-text-outline",
        prefix: "+ de ",
        value: 8,
        sufix: "MM",
        startValue: 0,
        text: "SMS enviados"
      },
      {
        id: 3,
        mediaSrc: "mdi-email-outline",
        prefix: "+ de ",
        value: 3,
        sufix: "MM",
        startValue: 0,
        text: "e-mails disparados"
      },
      {
        id: 4,
        mediaSrc: "/icons/iconMoney.svg",
        mediaType: "svg",
        prefix: "+ de ",
        value: 2,
        sufix: "MM",
        startValue: 0,
        text: "pagamentos efetuados"
      },
    ] as Array<AutoNumberCardItem>
  }),

  getters: {
    getMetricNumbers: (state) => state.metricNumbers,
  },
})